import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery2)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Us'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='Manly Community Forum'>
                <Text variant='p'>
                  We are locals who love Manly and care deeply about our local community.
                  We are entirely volunteer based and non-partisan.                   
                </Text>
                <Text variant='p'>
                  Our monthly meetings are on the third monday of every month at Manly Yacht Club.
                  Come to our montly meetings and join in the discussion, raise an issue, 
                  inspire others, or simply get to know your neighbours - everyone's  welcome.                  
                </Text>
                <Text variant='p'>
                  We produce a montly printed newsletter which is delivered to 4000 local addresses.
                  A digital version is produced for our subscribers.
                 </Text>
               
                <Text variant='p'>
                  The Manly Community Forum was established in July 2016 after the establishment 
                  of the Northern Beaches Council. It is based on the 'precincts' concept, 
                  bringing together in one Forum the 'precincts' formerly referred to as the Corso, 
                  Little Manly and Fairy Bower.
                </Text>
              
                <Text variant='p'>
                  We welcome any voluneers who would like to contribute, through writing for our monthly newsletter, help
                  with managing our website, delivering the printed newspaper or any other help you think you can offer.
                  Please get in contact with us.
                </Text>
                
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Our Team'>
                <Card variant='paper'>
                  <Text variant='p'>
                    The current executive committee consists of the following members
                  </Text>
                  <Text variant='p'>
                    <ul>
                      <li>Cathy Griffin, Chair</li>
                      <li>Kandy Tagg, Secretary</li>
                    </ul>
                  </Text>
                  <Text variant='p'>
                    We also have a very active communications team working behind the scenes to help bring you 
                    the articicles in our monthly newsletter. 
                  </Text>  
                </Card>
              </Section>
              <Divider />
              <Section title='Local issues'>
                <Card variant='paper'>
                  Issues that are regularly discussed include
                  <ul>
                    <li>Locals DA's and planning</li>
                    <li>Parking and traffic</li>
                    <li>Environment and the ocean</li>  
                    <li>Public works and amenities</li>
                    <li>Northern Beaches Council</li>
                    <li>Education and healthcare</li>
                    <li>Local By-laws</li>
                    <li>Emergency services and police</li>
                    <li>Transport and the Manly ferries</li>
                    <li>Tourism and employment</li>
                    <li>Youth and sporting activities</li>
                    </ul>
                </Card>
              </Section>
              
              <Divider />
              
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery2 = graphql`
  query AboutQuery2 {
    photo: file(absolutePath: { regex: "/northhead.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
