import React from 'react'
import { Card, Text } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
import Section from '@components/Section'
import useMailgun from '@helpers/useMailgun'

const NewsletterCompact = props => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success
  } = useMailgun()

  return (
      <Card variant='paper'>
        <Text variant='p'>
          Make sure to subscribe to our monthly digital newsletter to keep informed of all the local news.
        </Text>
        <NewsletterForm
          {...{ handleSubmit, canSubmit, submitting, message, success }}
          />
      </Card>
  )
}

export default NewsletterCompact
//<Section aside title='Newsletter' {...props}>
//   </Section>
   